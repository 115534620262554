import React from "react";

const tutorreportcolumns = () => [
  {
    Header: "Tutor",
    accessor: "tutor_name",
    Cell: ({ row }) => row.values.tutor_name || "-",
  },
  {
    Header: "Active Students",
    accessor: "students_with_remaining_hours",
    Cell: ({ row }) => row.values.students_with_remaining_hours || "-",
  },
  {
    Header: "Total Hours Unused",
    accessor: "total_hours_left",
    Cell: ({ row }) => row.values.total_hours_left || "-",
  },
  {
    Header: "Hours last 7 days",
    accessor: "used_hours_last_7_days",
    Cell: ({ row }) => row.values.used_hours_last_7_days || "-",
  },
  {
    Header: "Hours last 30 days",
    accessor: "used_hours_last_30_days",
    Cell: ({ row }) => row.values.used_hours_last_30_days || "-",
  },
  {
    Header: "Hours last 90 days",
    accessor: "used_hours_last_90_days",
    Cell: ({ row }) => row.values.used_hours_last_90_days || "-",
  },
  {
    Header: "% of Active Students with next Session Booked",
    accessor: "percentage_active_students_with_next_session",
    Cell: ({ row }) => {
      const percentage = parseFloat(
        row.values.percentage_active_students_with_next_session
      );
      const roundedPercentage = Math.round(percentage);
      return !isNaN(roundedPercentage) ? roundedPercentage + "%" : "0%";
    },
  },
  {
    Header: "Upcoming sessions 7 days",
    accessor: "upcoming_sessions_7_days_count",
    Cell: ({ row }) => row.values.upcoming_sessions_7_days_count || "-",
  },
  {
    Header: "Upcoming sessions 30 days",
    accessor: "upcoming_sessions_30_days_count",
    Cell: ({ row }) => row.values.upcoming_sessions_30_days_count || "-",
  },
];
export { tutorreportcolumns };
