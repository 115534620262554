import React from "react";

const VitalsAndGoals = ({ student, academicInfoPath }) => {
  const potentialMajor = () => {
    const { preliminary_major, student_college_info } = student;
    if (preliminary_major) {
      let major = preliminary_major;
      if (student_college_info?.major_commitment) {
        major += `, ${student_college_info.major_commitment}`;
      }
      return major;
    }
    return "Not reported";
  };

  return (
    <div className="card_student_dashboard">
      <div className="card__header_sidebar">
        <h2 className="title_student_dashboard">Vitals & Goals</h2>
        <a href={academicInfoPath}>Edit</a>
      </div>
      <div>
        <div className="vitals_and_goals_item">
          <div>
            <h3 className="subtitle_student_dashboard">
              Unweighted / weighted GPA
            </h3>
            <h5>
              {student.gpa_unweighted ?? "No reported"} /{" "}
              {student.gpa_weighted ?? "No reported"}
            </h5>
          </div>
        </div>
        <div className="vitals_and_goals_item">
          <div>
            <h3 className="subtitle_student_dashboard">Potential Major(s)</h3>
            <h5> {potentialMajor()}</h5>
          </div>
        </div>
        <div className="vitals_and_goals_item">
          <div>
            <h3 className="subtitle_student_dashboard">Career Interests</h3>
            <h5>
              {student.student_career_info?.career_interests ?? "No reported"}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitalsAndGoals;
